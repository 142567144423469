// GradientText.jsx
import React from "react";

const GradientText = ({ children, className = "" }) => {
  return (
    <span
      className={`font-bold text-transparent gradient-text animate-gradient ${className}`}
    >
      {children}
    </span>
  );
};

export default GradientText;
