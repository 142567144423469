// src/components/projects/projects.js
import quantercise from "../../../pics/quantercise.jpg";
import password from "../../../pics/password.jpg";
import portfolio from "../../../pics/portfolio_optimization.jpg";
import option from "../../../pics/option_pricing.jpg";
import strategy from "../../../pics/option_strategy.jpg";
import momentum_trading from "../../../pics/momentum_trading.jpg";
import taskspin from "../../../pics/taskspin.jpg";
import spykdb from "../../../pics/spykdb.jpg";
import habittracks from "../../../pics/habittracker.jpg";
import rss from "../../../pics/rss.jpg";

export const projects = [
  {
    title: "RSSLive Feed",
    date: "Feb 2025",
    brief:
      "Developed a Python script to aggregate RSS feeds from multiple sources and display them in a unified format.",
    description:
      "This Python script aggregates RSS feeds from multiple sources and displays them in a unified format. It allows users to stay updated on news, blogs, and other content from various websites.",
    imageUrl: rss,
    projectUrl: "#",
    codeUrl: "#",
    tools: [
      "C",
      "RSS",
      "Web Scraping",
      "Next.js",
      "TailwindCSS",
      "Node.js",
      "Express",
    ],
  },
  {
    title: "SPY Volatility Analysis with kdb+/q",
    date: "Feb 2025",
    brief:
      "Developed a kdb+/q database to analyze SPY options data for volatility trading strategies.",
    description:
      "Created a kdb+/q database to analyze SPY options data for volatility trading strategies. The system stores historical options data and allows for the analysis of both implied and realized volatility.",
    imageUrl: spykdb,
    projectUrl: "#",
    codeUrl: "https://github.com/anirudhp15/kdb-spy",
    tools: ["kdb+", "q", "Python", "Data Analysis"],
  },
  {
    title: "TaskSpin",
    date: "Jan 2025",
    brief:
      "A Google Chrome task management web extension that gamifies productivity through a spinning wheel.",
    description:
      "TaskSpin is a web extension that turns task management into a game by allowing users to spin a wheel to determine their next task. It offers an interactive and fun way to increase productivity through gamification.",
    imageUrl: taskspin,
    projectUrl: "https://taskspin.com",
    codeUrl: "#",
    tools: ["React", "Node.js", "TailwindCSS", "MongoDB"],
  },
  {
    title: "Quantercise",
    date: "July 2024",
    brief:
      "A platform for students to enhance their quantitative skills for trading and analyst internships through interactive exercises.",
    description:
      "Quantercise provides a set of interactive exercises designed to help students improve their quantitative skills for finance and trading. It helps aspiring analysts and traders prepare for internships in competitive fields.",
    imageUrl: quantercise,
    projectUrl: "https://quantercise.com",
    codeUrl: "#",
    tools: ["MERN Stack", "OpenAI", "Framer Motion", "Firebase"],
  },
  {
    title: "Parameterized Momentum Trading",
    date: "June 2024",
    brief:
      "Developed a Python script for automating momentum trading strategies on DJIA constituents, using a hyperparameterized approach to optimize performance over 20 years.",
    description:
      "This Python script automates momentum trading strategies on DJIA constituents using hyperparameter optimization. The model adjusts trading decisions based on market conditions and evaluates the strategy over a 20-year period.",
    imageUrl: momentum_trading,
    projectUrl:
      "https://github.com/anirudhp15/momentum-trading/blob/main/Parameter_Tuning_in_Momentum_Strategies.pdf",
    codeUrl:
      "https://github.com/anirudhp15/momentum-trading/blob/main/momemtum.ipynb",
    tools: ["Python", "Scikit-learn", "NumPy", "Matplotlib", "DataBento"],
  },
  {
    title: "HabitTracks",
    date: "Aug 2024",
    brief:
      "Developed an analytics dashboard to monitor realtime music artist trends for Atlantic Records subsidiary",
    description:
      "HabitTracks provides an analytics dashboard that monitors real-time trends of music artists. It helps Atlantic Records subsidiary track key metrics and trends for artists within their portfolio.",
    imageUrl: habittracks,
    projectUrl: "#",
    codeUrl: "#",
    tools: [
      "SQLite",
      "Python",
      "Chartmetric API",
      "CrawlScrape",
      "Streamlit",
      "Cron",
    ],
  },
  {
    title: "Option/Equity Interest Ratios",
    date: "May 2024",
    brief:
      "Developed a quant-driven trading strategy based on the option-to-equity volume ratio, utilizing CQAI data to balance exposure and optimize market returns.",
    description:
      "This project develops a trading strategy that uses the option-to-equity volume ratio to balance exposure. By leveraging CQAI data, the strategy optimizes market returns with a data-driven approach.",
    imageUrl: strategy,
    projectUrl: "#",
    codeUrl: "#",
    tools: ["Python", "Data Analysis", "CQAI", "Finance"],
  },
  {
    title: "Simple Portfolio Optimization",
    date: "April 2023",
    brief:
      "This Python tool applies advanced finance algorithms to optimize stock portfolios, improving returns and analyzing investment performance.",
    description:
      "This Python tool applies the Efficient Frontier model to optimize stock portfolios during the COVID-19 pandemic. The algorithm uses Sharpe Ratio analysis to identify portfolios that maximize returns while minimizing risk.",
    imageUrl: portfolio,
    projectUrl:
      "https://github.com/anirudhp15/Portfolio-Optimization/tree/main",
    codeUrl:
      "https://github.com/anirudhp15/Portfolio-Optimization/blob/main/portfolio_optimization.ipynb",
    tools: ["Python", "Efficient Frontier", "Jupyter Notebook"],
  },
  {
    title: "Option Pricing & Sensitivity Analysis",
    date: "March 2023",
    brief:
      "A Python program for pricing European options using the Black-Scholes and binomial models, along with sensitivity analysis for key parameters.",
    description:
      "This Python tool calculates the price of European options using the Black-Scholes and binomial models. It also performs sensitivity analysis to understand the impact of various parameters on option pricing.",
    imageUrl: option,
    projectUrl:
      "https://github.com/anirudhp15/Options-Pricing-and-Sensitivity-Analysis-Tool/tree/main",
    codeUrl:
      "https://github.com/anirudhp15/Options-Pricing-and-Sensitivity-Analysis-Tool/blob/main/options_pricing.py",
    tools: [
      "Python",
      "Black-Scholes",
      "Binomial Model",
      "Sensitivity Analysis",
    ],
  },
  {
    title: "Password Generator & Validator",
    date: "Feb 2023",
    brief:
      "A Python tool for generating strong, random passwords and validating their strength based on customizable rules.",
    description:
      "This Python tool generates random passwords with user-defined complexity. It also validates password strength according to customizable rules, ensuring secure password creation.",
    imageUrl: password,
    projectUrl: "https://github.com/anirudhp15/Password-Generator-Validator",
    codeUrl:
      "https://github.com/anirudhp15/Password-Generator-Validator/blob/main/project/project.py",
    tools: ["Python", "Security", "Random Generation", "Validation"],
  },
];
