import React, { useState, useEffect } from "react";

import { TbUser } from "react-icons/tb";

import { ReactTyped } from "react-typed";
import { motion } from "framer-motion";
import GradientText from "../designs/gradients/GradientText";
import ColorText from "../designs/ColorText";
import image1 from "../../pics/ani_pfp.jpg";
import image2 from "../../pics/fam1.jpg";
import image3 from "../../pics/fam2.jpg";
import "../../index.css";

const images = [image1, image2, image3];

const Introduction = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="about-me" className="relative z-10 bg-transparent pb-80">
      <div className="flex flex-col items-center min-h-screen mx-auto space-y-8 lg:flex-row lg:space-y-0 lg:space-x-12">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.5, ease: "easeOut" }}
          className="p-16 slider lg:w-1/2"
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="Ani Potts"
              className={`object-cover h-96 mx-auto outline outline-4 outline-white hover:outline-[#8d83ff] outline-offset-8 rounded-lg hover:-translate-y-2 lg:hover:scale-105 transition-all duration-300 shadow-xl hover:shadow-2xl lg:h-[30rem] xl:h-[36rem] ${
                index === currentSlide ? "block" : "hidden"
              }`}
            />
          ))}
        </motion.div>

        <div className="px-16 text-center lg:px-0 lg:pr-24 lg:w-1/2 lg:text-left">
          <div className="flex flex-row items-center justify-center py-6 text-5xl text-gray-300 lg:justify-normal">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <TbUser className="inline-block mr-2" />
            </motion.div>
            <ReactTyped
              className="text-4xl font-semibold tracking-tight text-indigo-400 lg:text-5xl"
              strings={["about me"]}
              typeSpeed={70}
              startDelay={500}
              backSpeed={50}
              startWhenVisible={true}
            />
          </div>

          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="pb-6 text-xl font-light leading-8 text-white"
          >
            as of right now i'm a junior at NYU studying math and computer
            science. i build data-driven apps for quant clubs at school,
            personal clients, and my own ventures — always focused on crafting{" "}
            <ColorText color="text-indigo-400">thoroughly practical</ColorText>{" "}
            solutions.
          </motion.p>

          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.7 }}
            className="pb-6 text-xl font-light leading-8 text-white"
          >
            i started learning{" "}
            <ColorText color="text-indigo-400">card tricks</ColorText> in third
            grade, which taught me early on to see the hidden, predictable
            patterns within situations of chance, a realization that has
            infiltrated my academic pursuits and my mentality moving forward.
            this early curiosity grew into a passion for{" "}
            <ColorText color="text-indigo-400">prediction</ColorText> and led me
            to machine learning — a tool that now fuels my work across
            industries, from exploring{" "}
            <ColorText color="text-indigo-400">
              medium-frequency trading strategies
            </ColorText>{" "}
            in US equity markets for my collegiate quant trading organization,
            or
            <ColorText color="text-indigo-400">
              {" "}
              forecasting campaign performance
            </ColorText>{" "}
            for artists under Atlantic Records.
          </motion.p>

          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.9 }}
            className="pb-12 text-xl font-light leading-8 text-white"
          >
            my near-term goal is to leverage ML methods and AI to improve math
            education. i aim to develop scalable tools that open new avenues for
            students in quantitative research.
          </motion.p>
        </div>
      </div>

      <div className="custom-shape-divider-bottom-1739234793">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 0L0 0 892.25 114.72 1200 0z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Introduction;
