import React from "react";
import Modal from "react-modal";
import placeholderLogo from "../../../pics/ani_pfp.jpg";
import aniLogo from "../../../pics/ani_logo.jpg";
import dadadig from "../../../pics/dadadig.jpg";
import pgi from "../../../pics/pgi.jpg";
import badhabit from "../../../pics/badhabit.jpg";
import atlanticRecords from "../../../pics/atlantic_records.jpeg";
import nyulogo from "../../../pics/NYU-Logo.jpeg";
import nyudentistry from "../../../pics/nyudentistry.jpeg";
import nyuit from "../../../pics/nyuit.png";
import mfg from "../../../pics/mfg.jpeg";
import { RiArrowDropRightLine } from "react-icons/ri";
import { AiOutlineSolution, AiOutlineEnvironment } from "react-icons/ai";
import "../../../index.css";
import { ReactTyped } from "react-typed";
import { motion } from "framer-motion";

Modal.setAppElement("#root");

const Experiences = () => {
  // Define the staggered container and card animation variants
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  // Updated experiences with gridSpan, impact as a list, and description as a list of strings.
  const experiences = [
    {
      name: "Mathematical Finance Group",
      logo: mfg,
      location: "New York, NY (NYU)",
      gridSpan: "md:row-span-1",
      roles: [
        {
          title: "Quantitative Trading Analyst",
          duration: "February 2024 - Present",
          tools: ["Python", "Pandas", "NumPy", "Matplotlib"],
          description: [
            "Utilized advanced statistical and probabilistic models to analyze financial markets.",
            "Developed trading strategies that improved signal accuracy and performance.",
          ],
          impact: ["Improved trading signal accuracy by 15%."],
        },
      ],
    },
    {
      name: "Paragon Global Investments",
      logo: pgi,
      location: "New York, NY (NYU)",
      gridSpan: "md:row-span-2", // This one spans both columns on medium+ screens
      roles: [
        {
          title: "Quantitative Researcher",
          duration: "November 2023 - September 2024",
          description: [
            "Executed SQL aggregations and merged data from CloudQuant AI to research and develop robust trading signals for a systematic trading fund.",
            "Trained and tested machine learning models using scikit-learn—including regression, SVM, and random forests—to optimize quantitative strategies.",
            "Enhanced trading signals by integrating EWMAs, market volatility bias indicators, and option-to-equity volume ratios, which drove measurable performance improvements.",
          ],
          impact: [
            "Managed approximately $40k AUM.",
            "Contributed to a 10% return improvement.",
          ],
        },
        {
          title: "Quantitative Research Lead",
          tools: [
            "Python",
            "AWS Lambda",
            "Scikit-learn",
            "TensorFlow",
            "Keras",
            "DataBento",
            "Git",
          ],
          duration: "September 2024 - Present",
          description: [
            "Led a 10-week machine learning project to train six analysts in LSTM-based US equities volatility forecasting and Vega trading, emphasizing RNN model development and hyperparameter tuning.",
            "Oversaw the deployment of live algorithmic trading software through AWS Lambda while maintaining code updates on GitHub.",
            "Drove strategic research and innovation for a multi-strategy trading fund, further positioning the firm as a leader in quantitative finance in the competitive NYC market.",
          ],
          impact: [
            "Driving innovation that further positions the fund in the competitive NYC market.",
          ],
        },
      ],
    },
    {
      name: "Online Mathematics Tutor",
      logo: aniLogo,
      location: "New York City Metropolitan Area",
      gridSpan: "md:row-span-1",
      roles: [
        {
          title: "Self-Employed Tutor",
          tools: ["Zoom", "Google Meet", "LaTeX"],
          duration: "January 2024 - Present",
          description: [
            "Tutored K–12 students in advanced arithmetic, algebra, and calculus.",
            "Created custom lesson plans to enhance student understanding and exam performance.",
          ],
          impact: ["Helped over 50 students improve their math grades."],
        },
      ],
    },

    {
      name: "New York University",
      logo: nyulogo, // Using the NYU logo as a common identifier
      location: "New York, NY",
      gridSpan: "md:row-span-2",
      roles: [
        {
          title: "Student Technology Assistant (NYU STC)",
          tools: ["Windows", "MacOS", "Linux", "MS Office", "Adobe CC"],
          duration: "July 2023 - Present",
          description: [
            "Supported NYU students and faculty by troubleshooting and managing campus software services.",
            "Ensured the smooth operation of NYU's technology resources.",
          ],
          impact: ["Enhanced IT service efficiency for over 500 users."],
        },
        {
          title: "Student Computer Technician (NYU College of Dentistry)",
          tools: ["Axium", "Romexus", "Adobe CC", "MS Office 365", "NYU VPN"],
          duration: "May 2023 - August 2023",
          description: [
            "Handled tier-1 help desk escalations for the college.",
            "Provided technical support using ServiceLink, ensuring minimal downtime.",
          ],
          impact: ["Resolved issues with a 95% satisfaction rate."],
        },
        {
          title: "Service Desk Support Agent (NYU IT)",
          tools: ["ServiceNow", "Zoom", "MS Teams", "WebEx", "Jamf"],
          duration: "April 2023 - August 2023",
          description: [
            "Assisted clients with IT service requests and troubleshooting for NYU IT services.",
            "Delivered prompt solutions that maintained system reliability.",
          ],
          impact: [
            "Boosted resolution speed by 30% through process improvements.",
          ],
        },
      ],
    },
    {
      name: "Dada Digital",
      logo: dadadig,
      location: "Remote / NYC",
      gridSpan: "md:row-span-1",
      roles: [
        {
          title: "Chief Data Scientist",
          tools: ["Squarespace", "JavaScript", "Python"],
          duration: "September 2024 - Present",
          description: [
            "Spearheaded data science initiatives to drive digital strategy and innovation.",
            "Oversaw development of advanced analytics projects.",
          ],
          impact: [
            "Delivered actionable insights that boosted digital engagement by 20%.",
          ],
        },
      ],
    },
    {
      name: "Our Bad Habit",
      logo: badhabit,
      location: "Remote / LA",
      gridSpan: "md:row-span-1",
      roles: [
        {
          title: "Data Analytics & Machine Learning Intern",
          tools: [
            "Python",
            "SciPy",
            "Scikit-learn",
            "Chartmetric API",
            "SQLite3",
            "BeautifulSoup",
          ],
          duration: "December 2023 - Present",
          description: [
            "Engineered a music artist trend analytics dashboard integrating Chartmetric’s API with web scraping.",
            "Applied machine learning to extract and present key insights.",
          ],
          impact: ["Enabled trend insights that increased engagement by 25%."],
        },
      ],
    },
  ];

  return (
    <div id="experience" className="relative w-full py-36">
      <div className="px-8 mx-auto max-w-screen-2xl">
        <div className="flex flex-row items-center justify-center py-6 text-5xl text-center text-gray-300">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-5xl text-center text-gray-300"
          >
            <AiOutlineSolution className="inline-block mr-2" />
          </motion.div>
          <ReactTyped
            strings={["experience"]}
            className="font-bold tracking-tighter text-indigo-400"
            startDelay={500}
            typeSpeed={70}
            backSpeed={50}
            startWhenVisible={true}
          />
        </div>
        {/* Wrap the grid with a motion.div to enable staggered children animations */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-1 gap-8 md:grid-cols-2"
        >
          {experiences.map((company, index) => (
            <motion.article
              key={index}
              variants={cardVariants}
              className={`p-8 flex flex-col justify-between transition-transform duration-300 transform bg-gray-100 rounded-lg shadow-lg group hover:-translate-y-2 hover:-translate-x-2 ${company.gridSpan}`}
            >
              {/* Top section with logo and company details */}
              <div className="flex flex-col lg:flex-row">
                {/* Logo */}
                <div className="flex items-start justify-center flex-shrink-0">
                  <img
                    src={company.logo}
                    alt={`${company.name} Logo`}
                    className="object-cover w-24 h-24 transition-all duration-300 rounded-lg shadow-md lg:w-32 lg:h-32 xl:w-48 xl:h-48 hover:shadow-xl outline outline-4 outline-gray-300 outline-offset-4 group-hover:outline-[#8d83ff]"
                  />
                </div>
                {/* Company details and roles */}
                <div className="flex-1 mt-4 lg:ml-6 lg:mt-0">
                  <header>
                    <h3 className="text-xl font-bold text-gray-800 lg:text-2xl">
                      {company.name}
                    </h3>
                    {company.location && (
                      <p className="flex items-center text-xs tracking-wide text-gray-400 lg:text-sm">
                        <AiOutlineEnvironment className="mr-1" />{" "}
                        {company.location}
                      </p>
                    )}
                  </header>
                  {company.roles.map((role, roleIndex) => (
                    <section key={roleIndex} className="my-4">
                      <header>
                        <h4 className="text-lg font-semibold text-blue-600">
                          {role.title}
                        </h4>
                        <p className="text-xs tracking-wide text-gray-400">
                          {role.duration}
                        </p>
                      </header>
                      <div className="mt-2 text-sm tracking-wide text-gray-800 ">
                        {role.description.map((desc, i) => (
                          <p key={i} className="mb-2">
                            {desc}
                          </p>
                        ))}
                      </div>
                      {role.impact && role.impact.length > 0 ? (
                        <ul className="mt-1 space-y-1 text-xs italic font-semibold text-gray-400 transition-all duration-300 opacity-25 group-hover:text-[#8d83ff] group-hover:opacity-75">
                          {role.impact.map((impactItem, i) => (
                            <li key={i}>
                              <RiArrowDropRightLine className="inline-block mr-1" />
                              {impactItem}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="mt-1 text-xs italic font-semibold text-gray-400">
                          [No impact metrics available]
                        </p>
                      )}
                    </section>
                  ))}
                </div>
              </div>
              {/* Tools section */}
              {company.roles.map((role, roleIndex) =>
                role.tools && role.tools.length > 0 ? (
                  <footer key={roleIndex} className="">
                    <h5 className="inline-block pb-1 font-bold text-gray-700 border-b-2 border-blue-400 text-md">
                      Tools
                    </h5>
                    <ul className="flex flex-wrap gap-2 mt-2">
                      {role.tools.map((tool, idx) => (
                        <li
                          key={idx}
                          className="px-3 py-1 text-xs font-semibold text-blue-800 transition transform bg-blue-100 rounded-full hover:scale-105"
                        >
                          {tool}
                        </li>
                      ))}
                    </ul>
                  </footer>
                ) : null
              )}
            </motion.article>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Experiences;
