import React, { useState } from "react";
import {
  AiFillLinkedin,
  AiFillGithub,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineMail,
  AiFillMail,
  AiOutlineInfoCircle,
  AiOutlinePhone,
  AiOutlineMessage,
} from "react-icons/ai";
import { ReactTyped } from "react-typed";
import { MdOutlineConnectWithoutContact } from "react-icons/md";

const Footer = () => {
  // Email form state
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  // State for toggling tutoring details dropdown
  const [isTutoringOpen, setIsTutoringOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:anirudhpottammal@nyu.edu?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      message
    )}%0D%0A%0D%0AFrom:%20${encodeURIComponent(email)}`;
    window.location.href = mailtoLink;
    setEmail(""); // Clear the input fields after submission
    setSubject("");
    setMessage("");
  };

  return (
    <footer
      id="footer2"
      className="relative z-10 w-full h-auto p-8 bg-gradient-to-b to-indigo-500 from-indigo-200"
    >
      <h1 className="flex flex-row items-center justify-center pt-4 text-4xl font-bold tracking-tighter text-center text-indigo-400">
        <MdOutlineConnectWithoutContact className="inline-block mr-2" />
        get in touch with me!
      </h1>
      <div className="py-8 text-center">
        <ReactTyped
          className="text-sm tracking-wide text-center text-white lg:text-lg"
          typeSpeed={20}
          backSpeed={50}
          startWhenVisible={true}
          strings={[
            "have a question or want to work together on a project? feel free to reach out!",
          ]}
        />
      </div>
      <div className="grid w-[90%] grid-cols-1 gap-8 mx-auto lg:grid-cols-6">
        {/* Tutoring Information Dropdown */}
        <div className="col-span-1 p-6 text-black duration-200 bg-white rounded-lg shadow-lg lg:col-span-6 transform-all hover:-translate-y-1">
          <h2
            onClick={() => setIsTutoringOpen(!isTutoringOpen)}
            className="flex flex-row items-center justify-center text-2xl font-bold tracking-tighter cursor-pointer"
          >
            <AiOutlineInfoCircle className="inline-block mr-2" />
            Tutoring Services
            <span className="ml-2">{isTutoringOpen ? "−" : "+"}</span>
          </h2>
          {/* Content container with smooth dropdown transition */}
          <div
            className={`transition-all duration-500 ease-in-out overflow-hidden ${
              isTutoringOpen ? "max-h-[2000px] py-4" : "max-h-0"
            }`}
          >
            <div className="text-lg">
              <h3 className="mb-8 text-sm font-thin text-center">
                Below is a detailed overview of the topics I offer personalized
                lessons for:
              </h3>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div>
                  <h4 className="font-bold text-center">
                    Elementary School Mathematics:
                  </h4>
                  <ul className="p-2 pl-5 space-y-1 text-sm font-normal list-disc bg-gray-100 rounded-md">
                    <li>
                      <span className="italic font-semibold">
                        Basic Arithmetic:
                      </span>{" "}
                      Addition, Subtraction, Multiplication, Division
                    </li>
                    <li>
                      <span className="italic font-semibold">
                        Fractions and Decimals
                      </span>{" "}
                      Ratios, Proportions, and Percentages
                    </li>
                    <li>
                      <span className="italic font-semibold">
                        Basic Geometry:
                      </span>{" "}
                      Shapes and their properties
                    </li>
                    <li>
                      <span className="italic font-semibold">
                        Intro to Measurements:
                      </span>{" "}
                      Length, Volume, Weight
                    </li>
                    <li>
                      <span className="italic font-semibold">
                        Time and Money:
                      </span>{" "}
                      Investing, Compounding, and Saving
                    </li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-bold text-center">
                    Middle School Mathematics:
                  </h4>
                  <ul className="p-2 pl-5 space-y-1 text-sm font-normal list-disc bg-gray-100 rounded-md">
                    <li>
                      <span className="italic font-semibold">Pre-Algebra:</span>{" "}
                      Fundamental Concepts, Equations, and Inequalities
                    </li>
                    <li>
                      <span className="italic font-semibold">Geometry:</span>{" "}
                      Perimeter, Area, Volume, Angles
                    </li>
                    <li>
                      <span className="italic font-semibold">
                        Intro to Number Theory:
                      </span>{" "}
                      Prime Numbers, Divisibility, and Factorization
                    </li>
                    <li>
                      <span className="italic font-semibold">
                        Intro to Statistics:
                      </span>{" "}
                      Mean, Median, Mode, and Deviations
                    </li>
                    <li>
                      <span className="italic font-semibold">Graphing:</span>{" "}
                      Coordinate Plane, Slope, and Intercepts
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h4 className="mt-4 font-bold text-center">
                  High School Mathematics:
                </h4>
                <ul className="p-2 pl-5 space-y-1 text-sm font-normal list-disc bg-gray-100 rounded-md">
                  <li>
                    <span className="italic font-semibold">
                      Algebra I & II:
                    </span>{" "}
                    Linear Equations, Quadratics, Polynomials, Rational
                    Expressions, Exponential and Logarithmic Functions
                  </li>
                  <li>
                    <span className="italic font-semibold">Geometry:</span>{" "}
                    Proof, Congruence, Similarity, Trigonometry, Circles, and
                    Volume
                  </li>
                  <li>
                    <span className="italic font-semibold">Trigonometry:</span>{" "}
                    Ratios, Functions, Graphs, and Identities
                  </li>
                  <li>
                    <span className="italic font-semibold">Pre-Calculus:</span>{" "}
                    Functions, Series, Limits, and Intro to Calculus Concepts
                  </li>
                  <li>
                    <span className="italic font-semibold">Calculus:</span>{" "}
                    Derivatives, Integrals, and their
                    interesting/counter-intuitive applications
                  </li>
                  <li>
                    <span className="italic font-semibold">
                      Discrete Mathematics:
                    </span>{" "}
                    Logic, Set Theory, Combinatorics, Graph Theory, and
                    Algorithms
                  </li>
                  <li>
                    <span className="italic font-semibold">
                      Probability Theory:
                    </span>{" "}
                    Axioms, Distributions, Expected Value, Combinatoric
                    Applications, and Conditional Probability
                  </li>
                  <li>
                    <span className="italic font-semibold">Statistics:</span>{" "}
                    Descriptive and Inferential Statistics, Hypothesis Testing,
                    and Regression
                  </li>
                </ul>
              </div>
              <p className="mt-2 text-sm text-center">
                <span className="font-bold">Note: </span>I provide original
                worksheets in LaTeX as practice assignments or homework for my
                teachings.
              </p>
              <h3 className="mt-8 text-lg font-bold text-center">
                Methods of Communication:
              </h3>
              <p className="text-sm text-center">
                Zoom, Google Meet, or In-person (Manhattan/Brooklyn)
              </p>
              <p className="mt-8 text-sm font-semibold text-center">
                Feel free to reach out via email at{" "}
                <span className="text-indigo-400">
                  anirudhpottammal@nyu.edu
                </span>{" "}
                to discuss scheduling and rates.
              </p>
            </div>
          </div>
        </div>

        {/* Email Form */}
        <div className="h-auto col-span-1 p-6 text-black duration-200 bg-white rounded-lg shadow-lg lg:col-span-2 transform-all hover:-translate-y-1">
          <h2 className="flex flex-row justify-center pb-8 text-2xl font-bold tracking-tighter">
            <AiOutlineMail className="mt-1 mr-2" />
            Contact Me
          </h2>
          <div className="flex flex-col items-center space-y-8">
            <a
              href="mailto:anirudhpottammal@nyu.edu"
              className="flex items-center space-x-2 text-sm font-semibold text-indigo-400 transition duration-100 hover:text-indigo-300"
            >
              <AiOutlineMail className="text-xl transition-all duration-200 ease-in-out transform hover:scale-125 hover:text-indigo-300" />
              <span className="pr-1 text-black">Email:</span>{" "}
              anirudhpottammal@nyu.edu
            </a>
            <a
              href="tel:+1-123-456-7890"
              className="flex items-center space-x-2 text-sm font-semibold text-indigo-400 transition duration-100 hover:text-indigo-300"
            >
              <AiOutlinePhone className="text-xl transition-all duration-200 ease-in-out transform hover:scale-125 hover:text-[#5a48ff]" />
              <span className="pr-1 text-black">Call:</span> +1 (732) 666 - 3371
            </a>
          </div>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center space-y-8"
          >
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="w-full px-4 py-2 mt-8 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent"
            />
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Subject"
              className="w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent"
            />
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Your Message"
              className="w-full px-4 py-2 border border-gray-300 bg-gray-100 h-auto min-h-[100px] lg:h-[300px] rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent"
            />
            <button
              type="submit"
              className="flex flex-row w-auto px-4 py-2 text-white transition-all duration-200 ease-in-out bg-indigo-400 rounded-lg text-bold hover:animate-pulse hover:bg-indigo-200 hover:text-indigo-400 whitespace-nowrap outline outline-4 outline-offset-4 outline-gray-300 hover:outline-indigo-400 hover:scale-105"
            >
              Send
              <AiOutlineMessage className="mt-1 ml-2 text-md" />
            </button>
          </form>
        </div>

        {/* Google Calendar Embed */}
        <div className="col-span-1 p-4 duration-200 bg-white rounded-lg lg:col-span-4 transform-all hover:-translate-y-1">
          <iframe
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2IFIHotzbZlKmJvfyczj3IW1Qe4adTuZbS0YE1s1m9QwsbEg08Ljqo4FnXB1m6Iv80v3l-Je4_?gv=true"
            style={{ border: 0 }}
            width="100%"
            height="600"
            frameBorder="0"
          ></iframe>
        </div>
      </div>

      {/* Social Icons & Copyright */}
      <div className="mt-8 text-center">
        <div className="flex justify-center space-x-4 text-white">
          <a
            href="https://www.linkedin.com/in/yourusername/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#0A66C2] hover:bg-white rounded-lg p-1 transition-all duration-200 hover:scale-110"
          >
            <AiFillLinkedin size={30} />
          </a>
          <a
            href="https://github.com/yourusername"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#181717] hover:bg-white rounded-lg p-1 transition-all duration-200 hover:scale-110"
          >
            <AiFillGithub size={30} />
          </a>
          <a
            href="https://www.instagram.com/yourusername/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#E4405F] hover:bg-white rounded-lg p-1 transition-all duration-200 hover:scale-110"
          >
            <AiFillInstagram size={30} />
          </a>
          <a
            href="https://www.youtube.com/channel/yourchannelID"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#FF0000] hover:bg-white rounded-lg p-1 transition-all duration-200 hover:scale-110"
          >
            <AiFillYoutube size={30} />
          </a>
          <a
            href="mailto:youremail@example.com"
            className="hover:text-[#000000] hover:bg-white rounded-lg p-1 transition-all duration-200 hover:scale-110"
          >
            <AiFillMail size={30} />
          </a>
        </div>
        <p className="mt-4 text-sm text-[#ddd5ff]">
          © {new Date().getFullYear()} AniPotts. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
