import { AiOutlineSend } from "react-icons/ai";
import { FaGraduationCap } from "react-icons/fa";
import { motion } from "framer-motion";
import { ReactTyped } from "react-typed";
import nyulogo from "../../../pics/nyu_logo.png";
import sbhs from "../../../pics/sbhs_logo.jpg";

const educationData = [
  {
    logo: nyulogo,
    alt: "NYU Logo",
    title: "New York University",
    subtitle: "Courant Institute of Mathematical Sciences",
    period: "August 2022 - May 2026",
    degree: "BA, Math and Computer Science",
    activities: ["Mathematical Finance Group", "Paragon Global Investments"],
    coursework: [
      "Calculus (Univariate & Multivariate)",
      "Linear Algebra",
      "Data Structures",
      "Real Analysis",
      "Abstract Algebra",
      "Theory of Probability",
    ],
  },
  {
    logo: sbhs,
    alt: "SBHS Logo",
    title: "South Brunswick High School",
    subtitle: "Honor Roll, 4.0",
    period: "September 2018 - June 2022",
    degree: "SAT Score: 1550 (Math: 800, English: 750)",
    activities: [
      "HiTops",
      "Junior State of America",
      "Public Health Club",
      "Math Club",
    ],
    coursework: [
      "AP Calculus AB & BC",
      "AP Physics C",
      "AP Statistics",
      "Honors Pre-Calculus",
      "AP Computer Science A",
    ],
  },
];

const EducationComponent = () => {
  const scrollToFooter = () => {
    const footerElement = document.getElementById("footer");
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Variants for the staggered container and each child card/button
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <section
      id="education"
      className="relative z-50 flex items-center justify-center w-full min-h-screen py-24 pb-56 text-gray-800 bg-transparent"
    >
      <div className="w-11/12 px-4 mx-auto md:w-3/4 lg:w-2/3">
        <div className="flex flex-row items-center justify-center py-6 text-5xl text-center text-gray-300">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-5xl text-center text-gray-300"
          >
            <FaGraduationCap className="inline-block mr-2 text-gray-300" />
          </motion.div>
          <ReactTyped
            strings={["education"]}
            className="font-bold tracking-tighter text-indigo-400"
            startDelay={500}
            typeSpeed={70}
            backSpeed={50}
            startWhenVisible={true}
          />
        </div>

        {/* Parent container with staggered animations */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {/* Education Cards */}
          <motion.div className="grid gap-12 lg:grid-cols-2">
            {educationData.map((edu, index) => (
              <motion.div
                key={index}
                variants={cardVariants}
                whileHover={{ scale: 1.02 }}
                className="p-8 transition-transform bg-white rounded-lg shadow-lg"
              >
                <div className="flex items-start space-x-4">
                  <img
                    src={edu.logo}
                    alt={edu.alt}
                    className="object-center w-12 h-16"
                  />
                  <div>
                    <h3 className="text-2xl font-bold text-gray-700">
                      {edu.title}
                    </h3>
                    <p className="font-semibold text-indigo-500 text-md">
                      {edu.subtitle}
                    </p>
                    <p className="mt-1 text-xs text-gray-500">{edu.period}</p>
                  </div>
                </div>
                <div className="mt-5 text-gray-700">
                  <p className="text-lg font-semibold">{edu.degree}</p>
                  <div className="mt-2 space-y-1">
                    <h4 className="font-semibold text-indigo-500 text-md">
                      Activities
                    </h4>
                    <ul className="pl-4 text-sm text-indigo-600 list-disc list-inside">
                      {edu.activities.map((activity, i) => (
                        <li key={i}>{activity}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-4">
                    <h4 className="font-semibold text-indigo-500 text-md">
                      Relevant Coursework
                    </h4>
                    <div className="flex flex-wrap mt-2 text-sm text-gray-600 gap-y-2 gap-x-2">
                      {edu.coursework.map((course, i) => (
                        <span
                          key={i}
                          className="px-3 py-1 bg-indigo-100 rounded-full"
                        >
                          {course}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Staggered Button */}
          <motion.div
            variants={cardVariants}
            className="flex justify-center mt-16"
          >
            <button
              onClick={scrollToFooter}
              className="flex items-center justify-center px-4 py-2 text-white transition-all duration-200 bg-indigo-400 rounded-lg shadow-xl hover:cursor-pointer hover:text-indigo-400 hover:bg-indigo-200 hover:scale-105 hover:outline-indigo-400 whitespace-nowrap outline outline-2 hover:shadow-2xl outline-white outline-offset-4"
            >
              <AiOutlineSend className="mr-2 text-xl" />
              Contact Me for Collaboration
            </button>
          </motion.div>
        </motion.div>
      </div>
      <div className="custom-shape-divider-bottom-1739234793">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 0L0 0 892.25 114.72 1200 0z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default EducationComponent;
