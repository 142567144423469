import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { motion } from "framer-motion";

import Navbar from "./components/navbar/Navbar";
import Hero from "./components/home/hero/Hero";

// import Projects from "./components/home/projects/Projects";
import LatestProjects from "./components/home/projects/LatestProjects";
import AllProjects from "./components/home/projects/AllProjects";

import Footer from "./components/footer/Footer";
import Introduction from "./components/about/Introduction";
import Education from "./components/background/education/Education";
import Experiences from "./components/background/experiences/Experiences";
import GradientBackground from "./components/designs/gradients/gradientBackground/GradientBackground";

import "./index.css";
import Blurb from "./components/home/hero/Blurb";

// Layout component that wraps every page with the Navbar, Footer, and GradientBackground
const MainLayout = () => {
  const fadeInGradient = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.2, ease: "easeOut" } },
  };

  return (
    <div className="relative w-full min-h-screen bg-black main-container">
      {/* Navbar is always visible */}
      <Navbar />

      {/* Gradient background */}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInGradient}
        className="fixed inset-0"
      >
        <GradientBackground />
      </motion.div>

      {/* The Outlet renders the page-specific content */}
      <div className="relative z-10">
        <Outlet />
      </div>

      {/* Footer is always visible */}
      <Footer />
    </div>
  );
};

// HomePage component that renders the hero and projects sections
const HomePage = () => (
  <>
    <Hero />
    <Blurb />
    <LatestProjects />
  </>
);

const EduExpPage = () => (
  <>
    <Experiences />
    <Education />
  </>
);

function App() {
  return (
    <Router>
      <Routes>
        {/* All routes use MainLayout to include Navbar, Footer, and background */}
        <Route element={<MainLayout />}>
          {/* Home page: shows Hero and Projects */}
          <Route path="/" element={<HomePage />} />

          {/* Other pages: show only their content */}
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/projects" element={<AllProjects />} />
          <Route path="/background" element={<EduExpPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
